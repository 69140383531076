import React from "react"

const url = typeof window !== "undefined" ? window.location.href : ""

const facebookIcon = (
  <svg
    width="9"
    height="20"
    viewBox="0 0 9 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 6.91616H5.98575V5.05146C5.98575 4.08928 6.05758 3.48325 7.32228 3.48325H8.91962V0.518371C8.14233 0.43073 7.36076 0.387842 6.57834 0.389707C4.25843 0.389707 2.56532 1.93461 2.56532 4.77082V6.91616H0V10.6456L2.56532 10.6446V19.0367H5.98575V10.6428L8.60751 10.6418L9 6.91616Z"
      fill="#191919"
    />
  </svg>
)

const twitterIcon = (
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5714 0.425781C19.7691 0.905396 18.1594 1.55904 17.2131 1.74757C17.1854 1.75482 17.1627 1.76414 17.136 1.77139C16.2998 0.940616 15.1539 0.425781 13.8857 0.425781C11.3297 0.425781 9.25714 2.51309 9.25714 5.08726C9.25714 5.22296 9.24583 5.47261 9.25714 5.6052C5.80834 5.6052 3.18343 3.78619 1.30114 1.46167C1.09646 1.97961 1.00697 2.79796 1.00697 3.56658C1.00697 5.01785 2.13326 6.44323 3.88697 7.32684C3.564 7.41075 3.20811 7.47083 2.83783 7.47083C2.24023 7.47083 1.60766 7.31234 1.02857 6.83169C1.02857 6.8493 1.02857 6.86587 1.02857 6.88452C1.02857 8.91278 3.16594 10.2936 5.06674 10.6779C4.68103 10.9069 3.90343 10.9296 3.52389 10.9296C3.25646 10.9296 2.31017 10.8064 2.05714 10.7587C2.58583 12.4213 4.4928 13.3557 6.31029 13.3888C4.8888 14.5117 3.9024 14.9282 0.991543 14.9282H0C1.83909 16.1153 4.18114 17.001 6.52834 17.001C14.1706 17.001 18.5143 11.1347 18.5143 5.6052C18.5143 5.51611 18.5122 5.32966 18.5091 5.14216C18.5091 5.12351 18.5143 5.1059 18.5143 5.08726C18.5143 5.05929 18.5061 5.03236 18.5061 5.00439C18.503 4.86351 18.4999 4.73195 18.4968 4.66358C19.3094 4.07313 20.0139 3.33662 20.5714 2.49755C19.8257 2.8311 19.0255 3.05485 18.1851 3.15637C19.043 2.63843 20.2618 1.40366 20.5714 0.425781Z"
      fill="#191919"
    />
  </svg>
)

const linkedInIcon = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16209 0.389648C0.968374 0.389648 0 1.39118 0 2.62781C0 3.86444 0.967594 4.88915 2.16049 4.88915C3.35339 4.88915 4.32258 3.86444 4.32258 2.62781C4.32258 1.39203 3.35581 0.389648 2.16209 0.389648ZM12.7096 6.32253C10.894 6.32253 9.85523 7.30611 9.35696 8.28423H9.30422V6.58574H5.72631V19.0363H9.45443V12.8731C9.45443 11.2491 9.57276 9.67977 11.5127 9.67977C13.4247 9.67977 13.4526 11.5315 13.4526 12.9758V19.0363H17.176H17.1808V12.1977C17.1808 8.85142 16.4863 6.32253 12.7096 6.32253ZM0.296515 6.58621V19.0368H4.02784V6.58621H0.296515Z"
      fill="#191919"
    />
  </svg>
)

const emailIcon = (
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7143 17.001H0V0.425781H21.7143V17.001ZM10.8676 11.3166L1.71415 6.8757V15.2249H19.9999V6.87689L10.8676 11.3166ZM19.9999 2.20144H1.71415V4.91266L10.857 9.32876L19.9999 4.91266V2.20144Z"
      fill="#191919"
    />
  </svg>
)

export default function Share(props) {
  return (
    <div className="flex items-center justify-between">
      <h3 className="h6 mb-0">Share</h3>
      <ul className="flex justify-center items-center space-x-2">
        <li>
          <a
            href={
              `https://twitter.com/intent/tweet?url=` +
              url +
              `&text=` +
              props.title
            }
            className="h-10 w-10 flex items-center justify-center bg-grey text-xs"
            target="_blank"
            rel="noreferrer"
          >
            {twitterIcon}
          </a>
        </li>
        <li>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=` + url}
            className="h-10 w-10 flex items-center justify-center bg-grey text-xs"
            target="_blank"
            rel="noreferrer"
          >
            {facebookIcon}
          </a>
        </li>
        <li>
          <a
            href={
              `https://www.linkedin.com/shareArticle?url=` +
              url +
              `&title=` +
              props.title
            }
            className="h-10 w-10 flex items-center justify-center bg-grey text-xs"
            target="_blank"
            rel="noreferrer"
          >
            {linkedInIcon}
          </a>
        </li>
        <li>
          <a
            href={
              `mailto:?subject=Check%20this%20out%20on%20the%20MET%20website&body=Here%20is%20the%20link:%20` +
              url
            }
            className="h-10 w-10 flex items-center justify-center bg-grey text-xs"
            target="_blank"
            rel="noreferrer"
          >
            {emailIcon}
          </a>
        </li>
      </ul>
    </div>
  )
}
