import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PortableTextBlock from "../components/PortableTextBlock"
import Seo from "../components/Seo"
import DirectoryMenu from "../components/DirectoryMenu"
import Share from "../components/Share"
import LoadMore from "../components/LoadMore"

const PageTemplate = ({ data }) => {
  const page = data && data.page

  return (
    <>
      <Seo
        metaTitle={page.seo?.metaTitle || page.title}
        shareTitle={page.seo?.shareTitle || page.title}
        metaDesc={page.seo?.metaDesc}
        shareDesc={page.seo?.shareDesc}
        shareGraphic={page.seo?.shareGraphic?.asset.url}
      />
      <section className="wrapper pt-6">
        {page.content?.featureImage && (
          <div className="mb-6">
            <GatsbyImage
              image={page.content.featureImage.asset.gatsbyImageData}
              alt={
                page.content.featureImage.asset.altText ||
                page.title + " feature image"
              }
            />
          </div>
        )}

        <div className="pb-3 border-b border-grey">
          <h1>{page.title}</h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 py-4">
          <div className="md:col-span-2">
            <div className="max-w-3xl w-full prose">
              <PortableTextBlock value={page.content?._rawBody} />
            </div>
          </div>
          <div className="flex flex-col divide-y divide-gray w-full">
            <div className="pb-3 order-last md:order-first">
              <Share title={page.seo?.shareTitle || page.title} />
            </div>
            {page.content?._rawDetails && (
              <div className="py-4">
                <span className="h6">Details</span>
                <div className="max-w-3xl w-full prose">
                  <PortableTextBlock value={page.content._rawDetails} />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="wrapper mb-4">
        <div className="border-t border-grey pt-2">
          <h2 className="h6">Related</h2>
          <LoadMore posts={page.relatedPosts} />
        </div>
      </section>
      <section className="wrapper">
        <div className="border-t border-grey pt-2">
          <h2 className="h6">Directory</h2>
          <DirectoryMenu cols={4} />
        </div>
      </section>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query newsQuery($_id: String!) {
    page: sanityNews(_id: { eq: $_id }) {
      id
      title
      content {
        _rawBody
        _rawDetails
        featureImage {
          asset {
            gatsbyImageData
            altText
          }
        }
      }
      seo {
        ...seoFields
      }
      relatedPosts {
        id
        _type
        title
        categories {
          id
          title
        }
        slug {
          current
        }
        content {
          subHeading
          featureImage {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
